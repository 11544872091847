import React from "react"

import Card from "react-bootstrap/Card"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WalletNavigation from "../components/wallet_navigation"
import CardDeck from "../components/card_deck"
import FormattedMarkdown from "../components/formatted_markdown"

import iconfinder_12_3319602 from "../images/iconfinder_12_3319602.png"
import iconfinder_6_3319634 from "../images/iconfinder_6_3319634.png"
import iconfinder_3_3319636 from "../images/iconfinder_3_3319636.png"

const WalletPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Wallet" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <WalletNavigation location={location} />
      <CardDeck>
        <WalletCard id="Wallet" img={iconfinder_12_3319602} />
        <WalletCard id="Fees" img={iconfinder_6_3319634} />
        <WalletCard id="Disclaimer" img={iconfinder_3_3319636} />
      </CardDeck>
    </Layout>
  )
}

const WalletCard = props => {
  const intl = useIntl()
  return (
    <Card>
      <Card.Img src={props.img} />
      <Card.Body>
        <Card.Title>{intl.formatMessage({ id: props.id })}</Card.Title>
        <Card.Text>
          <FormattedMarkdown id={"Wallet." + props.id} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default WalletPage
